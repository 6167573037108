<template>
  <article class="meta-page o-panel">
    <div class="meta-page__inner o-panel__inner">
      <div class="meta-page__content">
        <component :is="metaComponent" />
      </div>
    </div>
  </article>
</template>

<script>
const Imprint = () => import('./meta/Imprint')
const Privacy = () => import('./meta/Privacy')
const Terms = () => import('./meta/Terms')

const capitalize = s => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export default {
  components: {
    Imprint,
    Privacy,
    Terms
  },
  computed: {
    metaComponent() {
      return capitalize(this.$route.name)
    }
  },
  mounted() {
    this.$store.commit('setIsSiteLoading', false)
  }
}
</script>

<style lang="stylus">
@require '../assets/style/1-settings/*'

.meta-page
  padding-top 8rem
  background-color $colorSiteBackground

  &__inner
    margin-bottom 2rem

  &__content
    max-width 64rem

  a
    border-bottom 1px dotted currentColor

  header,
  section
    margin-bottom 2rem

.meta-page
  table
    table-layout fixed

.meta-page,
.meta-cookies
  table
    width 100%

    +below('s')
      font-size 0.7em

  th.whg-cookie-name:first-child
    width 8em

  .whg-cookie-status
    width 6em

  tr
    text-align left

  th, td
    border 1px solid #444
    vertical-align top
    text-align left
    padding 1rem

    +below('s')
      padding 0.3rem

  th
    letter-spacing -0.05em
    background-color #333

  ul
    padding-left 3rem
    margin-bottom 1em
    line-height 1.5

  h1
    font-size 3em
    margin-bottom 0.4em

  h2
    margin-top 1em
    margin-bottom 0.4em

  h3
    font-size 1.5em
    margin-bottom 0.5em

  h4
    font-size 1.2em
    margin-bottom 0.7em

  h5
    font-size 1em
    margin-bottom 0.2em

  hr
    margin-top 2rem
</style>
